<template>
    <div class="header__slider slick-initialized slick-slider" v-if="allBanners.length">
        <vue-slick-carousel
            ref="carousel"
            v-bind="settings"
            @afterChange="afterChange"
            :style="(page != 1 && is_mobile) ? 'height: 260px' : ''"
            :key="carouselKey"
        >
            <div
                v-for="(item, index) in allBanners"
                class="slider__content"
                :class="item.class"
                :key="index"
            >
                <div class="header__content" v-if="item.isStatic">
                    <div class="header__title" v-if="item.title">
                        <a :href="item.href" v-html="item.title"></a>
                    </div>
                    <div class="header__description" v-if="item.description">
                        <div class="header__text" v-html="item.description"></div>
                    </div>
                    <div v-if="item.img_front" class="header__picture">
                        <img
                            :src="require(`@/assets/img/slider/${item.img_front}`)"
                            :alt="item.img_front"
                        />
                    </div>
                    <a href="/about" class="inviting header__inviting" tabindex="0">Подробнее о компании</a>
                </div>

                <div class="header__nav">
                    <div class="header__arrows">
                        <button class="header__prev arrow-left arrow-white" @click="showPrev">
                        <svg class="icon icon-left">
                            <use xlink:href="@/assets/img/elem/symbols.svg#icon-left"></use>
                        </svg>
                        </button>
                        <button class="header__next arrow-right arrow-white" @click="showNext">
                        <svg class="icon icon-right">
                            <use xlink:href="@/assets/img/elem/symbols.svg#icon-right"></use>
                        </svg>
                        </button>
                    </div>
                </div> 

                <div class="header__back" :style="(index === 0 && is_tablet) ? 'width: 1150px;' : ''">
                    <div v-if="item.img_back" class="header__back-static">
                        <img
                            class="slider__back"
                            :src="require(`@/assets/img/slider/${item.img_back}`)"
                            :alt="item.img_back"
                        />
                    </div>
                    <!-- <div v-if="item.isDynamic" class="header__back-dynamic"> -->
                    <div v-else class="header__back-dynamic">
                        <a :href="item.link">
                            <img
                                :src="getImage('banners', item.image)"
                                alt="Banner"
                                class="slider__back"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </vue-slick-carousel>
  
        <div class="header__navbar">
            <div class="header__nav">
            <div class="header__counter header__counter-white">
                <span class="header__current">{{ page }}</span>
                <span class="header__separator header-white"> / </span>
                <span class="header__total header-white">{{ max_page }}</span>
            </div>
            <div class="header__arrows">
                <button class="header__prev arrow-left arrow-white" @click="showPrev">
                <svg class="icon icon-left">
                    <use xlink:href="@/assets/img/elem/symbols.svg#icon-left"></use>
                </svg>
                </button>
                <button class="header__next arrow-right arrow-white" @click="showNext">
                <svg class="icon icon-right">
                    <use xlink:href="@/assets/img/elem/symbols.svg#icon-right"></use>
                </svg>
                </button>
            </div>
            </div>
        </div>
    </div>
  </template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import SliderItemsJson from '@/assets/data/slider_header.json';
import utils from '../utils';

export default {
  components: {
    VueSlickCarousel
  },
  methods: {
    async getBanners() {
      const { fetchData } = utils();
      let target = `banners?all=false`;
      let content = await fetchData('get', target);
      if (content) {
        this.banners = content.data.map(banner => ({
          ...banner,
          isDynamic: true
        }));
        
      }

    },

    getImage(type, name) {
        const { getStaticImage } = utils();
        return getStaticImage(type, name);
    },


    showNext() {
      if (this.delay) {
        this.delay = false;
        this.$refs.carousel.next()
        this.page = this.page + 1 > this.max_page ? 1 : this.page + 1
        setTimeout(() => this.delay = true, this.settings.speed);
      }
    },
    showPrev() {
      if (this.delay) {
        this.delay = false;
        this.$refs.carousel.prev()
        this.page = this.page - 1 < 1 ? this.max_page : this.page - 1
        setTimeout(() => this.delay = true, this.settings.speed);
      }
    },
    afterChange(page) {
      this.page = page + 1
      //console.log(this.page);
    },
  },
    computed: {
        allBanners() {
            //return [...this.slider_items.data, ...this.banners];
            // return [...this.banners, ...this.slider_items.data];
            return [...this.banners];
        },
        max_page() {
            return this.allBanners.length;
        }
    },
    watch: {
        allBanners() {
            // Принудительно обновляем карусель при изменении баннеров
            this.carouselKey += 1;
        }
    },
    data() {
        return {
        settings: {
            "dots": false,
            "arrows": false,
            "fade": true,
            "edgeFriction": 0.35,
            "infinite": true,
            "speed": 500,
            "slidesToShow": 1,
            "slidesToScroll": 1
        },
        delay: true,
        page: 1,
        slider_items: SliderItemsJson,
        is_tablet: false,
        is_mobile: false,
        banners: [],
        carouselKey: 0,
        }
    },
    async beforeMount() {
        this.is_tablet = window.screen.width < 1199
        this.is_mobile = window.screen.width < 767
        await this.getBanners()
    }
}
</script>

